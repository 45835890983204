import React, { useState } from "react";
import { GrSearch } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

const Search = ({ className, inputClass, buttonClass }) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      if (query) {
        navigate(`/filter/category/vse?search=${query}`);
      } else {
        navigate(`/filter/category/vse`);
      }
    }
  };
  const onChange = (e) => {
    setQuery(e.target.value);
  };
  const onClick = () => {
    if (query) {
      navigate(`/filter/category/vse?search=${query}`);
    } else {
      navigate(`/filter/category/vse`);
    }
  };

  return (
    <div
      className={`${className} w-full h-full rounded-main border border-[hsla(220,4%,48%,.3)]`}
    >
      <input
        // className={`${inputClass} xl:w-full lg:w-[350px] md:w-[250px] rounded-main px-4 placeholder-priceGray font-500 text-sm`}
        className={`${inputClass} w-full rounded-main px-4 placeholder-priceGray font-500 text-sm`}
        type="search"
        placeholder="Искать товары и категории"
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
      <button
        onClick={onClick}
        className={`${buttonClass} bg-[hsla(220,4%,48%,.05)] w-[75px] flex items-center justify-center`}
      >
        <GrSearch size={20} />
      </button>
    </div>
  );
};

export default Search;
