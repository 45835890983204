import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "../../../assets/utils/ArrowRight";

const MenuItem = ({ item, className, onMouseEnter, active }) => {
  return (
    <Link
      to={`/filter/category/${item?.slug}`}
      className={`group flex items-center gap-[8px] p-[11px] hover:bg-[#76797f1a] rounded-l-[4px] ${className} ${
        active && "bg-[#76797f1a]"
      }`}
      onMouseEnter={onMouseEnter}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.75"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 17H3V19C3 19.5523 3.44772 20 4 20H5C5.55228 20 6 19.5523 6 19V17ZM21 17H18V19C18 19.5523 18.4477 20 19 20H20C20.5523 20 21 19.5523 21 19V17Z"
          fill="#FF1919"
        ></path>
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.6845 4C5.84442 4 5.09389 4.52498 4.80579 5.31412L2.70557 11.0669C3.25588 10.4145 4.07944 10 4.99981 10H18.9998C19.9202 10 20.7438 10.4145 21.2941 11.067L19.1939 5.31412C18.9058 4.52499 18.1552 4 17.3152 4H6.6845Z"
          fill="#FF1919"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.84903 9H3.50006L2.77433 10.9884C3.3233 10.3814 4.11711 10 5 10H19C20.6569 10 22 11.3431 22 13V16C22 17.1046 21.1046 18 20 18H4C2.89543 18 2 17.1046 2 16V13C2 12.2316 2.28885 11.5308 2.76389 11H2.34903C2.15965 11 1.98652 10.893 1.90182 10.7236L1.40182 9.72361C1.23559 9.39116 1.47734 9 1.84903 9ZM22.1911 9H20.5401L21.2701 11H21.6911C21.8805 11 22.0536 10.893 22.1383 10.7236L22.6383 9.72361C22.8046 9.39116 22.5628 9 22.1911 9ZM7 14C7 12.8954 6.10457 12 5 12C4.44772 12 4 12.4477 4 13V13.5C4 14.3284 4.67157 15 5.5 15H6.4C6.73137 15 7 14.7314 7 14.4V14ZM17 14C17 12.8954 17.8954 12 19 12C19.5523 12 20 12.4477 20 13V13.5C20 14.3284 19.3284 15 18.5 15H17.6C17.2686 15 17 14.7314 17 14.4V14Z"
          fill="#FF1919"
        ></path>
        <path
          opacity="0.25"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 12C6.10457 12 7 12.8954 7 14V14.4C7 14.7314 6.73137 15 6.4 15H5.5C4.67157 15 4 14.3284 4 13.5V13C4 12.4477 4.44772 12 5 12ZM19 12C17.8954 12 17 12.8954 17 14V14.4C17 14.7314 17.2686 15 17.6 15H18.5C19.3284 15 20 14.3284 20 13.5V13C20 12.4477 19.5523 12 19 12Z"
          fill="#FF1919"
        ></path>
      </svg>
      <div className="font-600 text-sm text-[#141415] group-hover:text-[#e63737] leading-[1.1]">
        {item?.name}
      </div>
      <ArrowRight className="ml-auto text-[13px] fill-[#A0A2A7]" />
    </Link>
  );
};

export default MenuItem;
