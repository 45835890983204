import React from "react";
import "../../assets/scss/_components.scss";

const PageLoading = () => {
  return (
    <>
      <div className="spinner-bg"></div>
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </>
  );
};

export default PageLoading;
