import { toast } from "react-toastify";
import { removeToken, requests, setToken } from "../helpers/helpers";
import { useMutation, useQuery } from "react-query";
import { APP_STATE } from ".";
import { queryClient } from "..";

export function useLogin() {
  const createLogin = async (params) => {
    try {
      const response = await requests.postLogin(params);
      if (response?.data?.token) {
        setToken(response?.data?.token);
        toast.success("Успешно");
        queryClient.setQueryData(APP_STATE.ME, response?.data?.user);
      }
      return response.data;
    } catch ({ response }) {
      toast.error(response?.data?.message?.[0]);
      return response?.data;
    }
  };

  const { mutate, mutateAsync, isLoading, isSuccess, isError } =
    useMutation(createLogin);

  return { mutate, mutateAsync, isLoading, isSuccess, isError };
}

export function useMe() {
  return useQuery(APP_STATE.ME, async () => {
    try {
      const response = await requests.fetchMe();
      if (response.status === 401) {
        removeToken();
      }
      return response?.data?.data;
    } catch (err) {
      throw new Error("Failed to fetch me");
    }
  });
}
