import React from "react";
import { BiLogoFacebook, BiLogoTwitter } from "react-icons/bi";
import { AiFillInstagram } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";

const SocialSidebar = () => {
  return (
    <nav className="social">
      <ul>
        {/* <li className="twitter">
          <a href="https://t.me/Abduganiev_R">
            <div>
              <BiLogoTwitter />
            </div>
            Twitter
          </a>
        </li> */}
        <li className="facebook">
          <a href="https://www.facebook.com/bmixuz">
            <div>
              <BiLogoFacebook />
            </div>
            Facebook
          </a>
        </li>
        <li className="telegram">
          <a href="https://t.me/bmixuz">
            <div>
              <FaTelegramPlane color="#000" />
            </div>
            Telegram
          </a>
        </li>
        <li className="instagram">
          <a href="https://www.instagram.com/bmix_uz">
            <div>
              <AiFillInstagram />
            </div>
            Instagram
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default SocialSidebar;
