import React from "react";
import { Link } from "react-router-dom";
import LogoImage from "../../assets/images/logo.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Logo = ({ className }) => {
  return (
    <Link to="/" className={className}>
      <LazyLoadImage effect="blur" src={LogoImage} alt="LOGO" />
    </Link>
  );
};

export default Logo;
