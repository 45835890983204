import { useNavigate } from "react-router-dom";
import { getToken } from "./helpers/helpers";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PrivateRoutes = ({ child }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const token = getToken();
    if (!token) {
      navigate("/");
    }
  }, [location]);
  return child;
};

export default PrivateRoutes;
