import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { IoCloseOutline, IoLogoStackoverflow } from "react-icons/io5";
import Accordion from "../accordion/Accordion";
import MenuItem from "./components/MenuItem";
import MobileItem from "./components/MobileItem";
import { AiOutlineHeart } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import { GiTeleport } from "react-icons/gi";
import { GrContactInfo } from "react-icons/gr";
import { BsTelephone } from "react-icons/bs";
import { removeProbels } from "../../helpers/helpers";
import { useContacts } from "../../hooks/useHelpers";
import { APP_STATE } from "../../hooks";
import { useCategories } from "../../hooks/useCategory";

const MobileMenu = ({ visible, setVisible }) => {
  const { data: contacts } = useQuery(APP_STATE.CONTACTS, useContacts());
  const { data: categories, refetch: refetchCategories } = useCategories();
  useEffect(() => {
    refetchCategories();
  }, [refetchCategories]);

  return (
    <div className={`mobile-menu ${visible && "show"}`}>
      <div className="flex items-center justify-end px-4 py-3 bg-white">
        <button className="ml-auto" onClick={setVisible}>
          <IoCloseOutline size={28} />
        </button>
      </div>
      <div className="px-4 my-[7px] bg-white">
        <Accordion
          title="Каталог"
          content={categories?.data?.map((item, idx) => (
            <MenuItem
              item={item}
              key={idx}
              className="px-0 py-2 border-t border-[#76797f33]"
            />
          ))}
        />
      </div>
      <div className="px-4 bg-white">
        <MobileItem Icon={AiOutlineHeart} title="Избранное" />
        <MobileItem Icon={BiMap} title="Город: Ташкент" />
        <MobileItem Icon={GiTeleport} title="Портфолио" />
        <MobileItem Icon={IoLogoStackoverflow} title="Нанесение логотипа" />
        <MobileItem Icon={GrContactInfo} title="Контакты" />
        <hr className="block border-t border-[#76797f33]" />
        <a
          className="flex items-center gap-2 py-2 text-[12.25px] text-[#141414] font-600"
          href={`tel:${removeProbels(contacts?.first_phone)}`}
        >
          <BsTelephone size={20} />
          {contacts?.first_phone}
        </a>
        <a
          className="flex items-center gap-2 py-2 text-[12.25px] text-[#141414] font-600"
          href={`tel:${removeProbels(contacts?.second_phone)}`}
        >
          <BsTelephone size={20} />
          {contacts?.second_phone}
        </a>
      </div>
    </div>
  );
};

export default MobileMenu;
