import React, { useState } from "react";
import { AiOutlineHeart, AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useContacts } from "../../hooks/useHelpers";
import { useQuery } from "react-query";
import { getFavoriteProducts } from "../../hooks/useFavorites";
import { useLogin, useMe } from "../../hooks/useAuth";
import { APP_STATE } from "../../hooks";
import { FaTelegram } from "react-icons/fa";
import { RiUserLine } from "react-icons/ri";
import Modal from "../modal/Modal";
import Input from "../input/Input";
import Button from "../button/Button";
import { toast } from "react-toastify";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";

const Inner = () => {
  const navigate = useNavigate();
  const { data: contacts } = useContacts();
  const { data: favoriteProducts, isLoading } = useQuery(
    APP_STATE.FAVORITE_PRODUCTS,
    () => getFavoriteProducts()
  );
  const { data: user, isLoading: userLoading } = useMe();
  console.log("navbar user", user);
  const loginMutation = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [authModal, setAuthModal] = useState(false);
  const handleAuthModal = (val) => {
    setAuthModal(val);
  };
  const [params, setParams] = useState({
    code: "",
    password: "",
  });
  const handleChangeParams = (name, value) => {
    setParams((prev) => ({ ...prev, [name]: value }));
  };
  const onFinish = async () => {
    if (!params.code) {
      toast.error("Введите ID");
    } else if (!params.password) {
      toast.error("Введите пароль");
    } else {
      loginMutation.mutateAsync(params).then((res) => {
        if (res?.token) {
          setAuthModal(false);
          navigate("/profile");
        }
      });
    }
  };

  return (
    <>
      <div className="flex gap-2 h-full ml-auto">
        <button
          onClick={() => {
            if (user?.id) {
              navigate("/profile");
            } else {
              handleAuthModal(true);
            }
          }}
          disabled={userLoading}
          className="flex items-center gap-[9px] relative text-black hover:bg-bgButton rounded-[4px] md:px-4 h-full"
        >
          <RiUserLine size={24} />
          <div className="font-500 text-sm">
            {user?.id ? "Профиль" : "Войти"}
          </div>
        </button>
        <button
          onClick={() => navigate("/favorites")}
          className="flex items-center gap-[9px] relative text-black hover:bg-bgButton rounded-[4px] md:px-4 h-full"
        >
          <div className="absolute md:left-[30px] left-[15px] top-[0px] bg-[#ff3336] rounded-full text-[#fff] min-w-[18px] h-[18px] text-[12px] flex items-center justify-center">
            {isLoading ? (
              <AiOutlineLoading3Quarters className="rotate-animation" />
            ) : (
              favoriteProducts?.length
            )}
          </div>
          <AiOutlineHeart size={24} />
          <div className="font-500 text-sm">Избранное</div>
        </button>
        <div className="xl:block hidden">
          <a
            className="text-sm flex items-center gap-2 text-[#e53935] font-600 whitespace-nowrap"
            href={`https://t.me/bmix_uz`}
          >
            <FaTelegram className="text-[18px]" />
            {contacts?.first_phone}
          </a>
          <a
            className="text-sm flex items-center gap-2 text-[#e53935] font-600 whitespace-nowrap"
            href={`https://t.me/bmix_manager`}
          >
            <FaTelegram className="text-[18px]" />
            {contacts?.second_phone}
          </a>
        </div>
      </div>
      <Modal
        active={authModal}
        onClose={() => handleAuthModal(false)}
        setActive={setAuthModal}
      >
        <div className="absolute top-[32px] left-[50%] translate-x-[-50%] font-500 text-[20px] text-center">
          Авторизация
        </div>
        <div className="mt-4">
          <div className="mb-2">ID</div>
          <Input
            value={params.code}
            onChange={(e) => handleChangeParams("code", e.target.value)}
            className={"md:w-[370px]"}
          />
        </div>
        <div className="mt-6">
          <div className="mb-2">Пароль</div>
          <div className="relative">
            <Input
              value={params.password}
              type={showPassword ? "text" : "password"}
              onChange={(e) => handleChangeParams("password", e.target.value)}
              className={"md:w-[370px]"}
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              className="absolute top-[50%] right-[15px] translate-y-[-50%] hover:bg-slate-100 rounded-full h-[32px] w-[32px] flex items-center justify-center cursor-pointer"
            >
              {!showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </div>
          </div>
        </div>
        <Button
          onClick={onFinish}
          name={"Отправить"}
          className={"mt-8 w-full"}
        />
      </Modal>
    </>
  );
};

export default Inner;
