import i18n from "../i18n";
import { API_URL } from "../config/index";

import axios from "axios";

export const $api = axios.create({
  baseURL: API_URL,
});

$api.defaults.headers.common["Accept"] = "application/json";
$api.defaults.headers.common["Content-Type"] =
  "application/json; charset=utf-8";

export const formData = (obj, form, namespace) => {
  const fd = form || new FormData();
  let formKey;

  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        formData(obj[property], fd, property);
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

export const removeProbels = (value) => {
  return value?.replace(/\s/g, "");
};

export const findCategoryBySlug = (categories, category_id) => {
  for (const category of categories) {
    if (category?.id === category_id) {
      return category;
    } else if (
      category?.sub_categories &&
      category?.sub_categories?.length > 0
    ) {
      const childCategory = findCategoryBySlug(
        category?.sub_categories,
        category_id
      );
      if (childCategory) {
        return childCategory;
      }
    }
  }
  return null;
};

const tokenName = "bmixToken";

export const initApp = () => {
  const token = window.localStorage.getItem(tokenName);
  $api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const setToken = (token) => {
  window.localStorage.setItem(tokenName, token);
  $api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const getToken = (token) => {
  return window.localStorage.getItem(tokenName);
};

export const removeToken = () => {
  window.localStorage.removeItem(tokenName);
  $api.defaults.headers.common.Authorization = ``;
};

// Language
$api.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.language.toLowerCase();
  return config;
});

export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  $api.defaults.headers.common["Accept-Language"] = lng;
  localStorage.setItem("bmixLanguage", lng);
};

export const requests = {
  // AUTH
  postLogin: (params) => $api.post("/login", formData(params)),
  fetchMe: () => $api.get("/getMe"),
  fetchCategories: () => $api.get("/getCategories"),
  fetchProducts: (params) => $api.get("/getProducts", { params }),
  fetchSingleProduct: (id) => $api.get(`/getSingleProduct/${id}`),
  fetchProductsBySearch: (params) => $api.get(`/search`, params),
  fetchPortfolio: () => $api.get(`/getPortfolio`),
  fetchContacts: () => $api.get(`/getContacts`),
  fetchLogos: () => $api.get("/getLogos"),
  fetchSingleLogo: (id) => $api.get(`/getSingleLogo/${id}`),
  fetchBanners: () => $api.get(`/getBanners`),
  fetchPartners: () => $api.get(`/getPartners`),
  postCreateOrder: (params) => $api.post(`/createOrder`, params),
  postChooseGift: (params) => $api.post(`/chooseGift`, params),
  fetchUserChanges: () => $api.get(`/getUserChanges`),
  fetchGifts: () => $api.get(`/getGifts`),
  fetchStocks: () => $api.get(`/getStocks`),
  fetchReports: () => $api.get(`/getReports`),
  fetchTerms: () => $api.get(`/getTerms`),
  fetchUserOrders: () => $api.get(`/getUserOrders`)
};
