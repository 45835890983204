import { useQuery } from "react-query";
import { requests } from "../helpers/helpers";
import { APP_STATE } from ".";

export function useCategories() {
  return useQuery(APP_STATE.CATEGORIES, async () => {
    try {
      const response = await requests.fetchCategories();
      return response.data;
    } catch (err) {
      throw new Error("Failed to fetch categories");
    }
  });
}
