import { lazy } from "react";

const Home = lazy(() => import("./views/home/Home"));
const Favorites = lazy(() => import("./views/favorites/Favorites"));
const Search = lazy(() => import("./views/search/Search"));
const DetailProduct = lazy(() => import("./views/detail/DetailCart"));
const Filter = lazy(() => import("./views/filter/Filter"));
const Portfolio = lazy(() => import("./views/portfolio/Portfolio"));
const Application = lazy(() => import("./views/application/Application"));
const ApplicationDetail = lazy(() =>
  import("./views/applicationDetail/ApplicationDetail")
);
const Contacts = lazy(() => import("./views/contacts/Contacts"));
const OrderSuccess = lazy(() => import("./views/order/OrderSuccess"));
const AboutCompany = lazy(() => import("./views/aboutCompany/AboutCompany"));
const Job = lazy(() => import("./views/job/Job"));
const BecomeSupplier = lazy(() =>
  import("./views/becomeSupplier/BecomeSupplier")
);
const BrandKey = lazy(() => import("./views/brandKey/BrandKey"));
const Profile = lazy(() => import("./views/privateViews/Profile"));
const ExchangeHistory = lazy(() =>
  import("./views/privateViews/ExchangeHistory")
);
const Stocks = lazy(() => import("./views/privateViews/Stocks"));
const Reports = lazy(() => import("./views/privateViews/Reports"));
const Terms = lazy(() => import("./views/privateViews/Terms"));
const Gifts = lazy(() => import("./views/privateViews/Gifts"));
const UserOrders = lazy(() => import("./views/privateViews/UserOrders"));

export const routes = [
  {
    name: "Главная",
    path: "/",
    element: Home,
    exact: true,
  },
  {
    name: "Избранные",
    path: "/favorites",
    element: Favorites,
  },
  {
    name: "Поиск",
    path: "/search/:slug",
    element: Search,
  },
  {
    name: "Товар",
    path: "/product/:id",
    element: DetailProduct,
  },
  {
    name: "Товар",
    path: "/filter/category/:slug",
    element: Filter,
  },
  {
    name: "Портфолио",
    path: "/portfolio",
    element: Portfolio,
  },
  {
    name: "Нанесение",
    path: "/application",
    element: Application,
  },
  {
    name: "Нанесение",
    path: "/application/:id",
    element: ApplicationDetail,
  },
  {
    name: "Контакты",
    path: "/contacts",
    element: Contacts,
  },
  {
    name: "Заказ",
    path: "/order-success",
    element: OrderSuccess,
  },
  {
    name: "О компании",
    path: "/about-company",
    element: AboutCompany,
  },
  {
    name: "Вакансии",
    path: "/about/job",
    element: Job,
  },
  {
    name: "Стать партнером",
    path: "/become-supplier",
    element: BecomeSupplier,
  },
  {
    name: "Бренды под ключ",
    path: "/brand-key",
    element: BrandKey,
  },
];

export const _private_routes = [
  {
    name: "Профиль",
    path: "/profile",
    element: Profile,
  },
  {
    name: "История обмена",
    path: "/exchange-history",
    element: ExchangeHistory,
  },
  {
    name: "Акции",
    path: "/stocks",
    element: Stocks,
  },
  {
    name: "Фото отчёты",
    path: "/reports",
    element: Reports,
  },
  {
    name: "Условия акции",
    path: "/terms",
    element: Terms,
  },
  {
    name: "Обмены",
    path: "/gifts",
    element: Gifts,
  },
  {
    name: "Заказы",
    path: "/user-orders",
    element: UserOrders,
  },
];
