import { Suspense } from "react";
import PageLoading from "./components/loading/PageLoading";
import { _private_routes, routes } from "./_routes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./layouts/navbar/Navbar";
import ScrollToTop from "./components/navbar/ScrollToTop";
import SecondNavbar from "./layouts/secondNavbar/SecondNavbar";
import "react-lazy-load-image-component/src/effects/blur.css";
import Footer from "./layouts/footer/Footer";
import NavbarTop from "./layouts/navbar/NavbarTop";
import { ToastContainer } from "react-toastify";
import PrivateRoutes from "./PrivateRoutes";
import "react-toastify/dist/ReactToastify.css";
import { initApp } from "./helpers/helpers";

const App = () => {
  initApp();
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoading />}>
        <ToastContainer position="top-right" className={"!z-[99999]"} />
        <ScrollToTop />
        <NavbarTop />
        <Navbar />
        <SecondNavbar />
        <Routes>
          {routes?.map(({ path, element: Component, exact }, idx) => (
            <Route
              key={idx}
              path={path}
              exact={exact}
              element={<Component />}
            />
          ))}
          {_private_routes?.map(({ path, element: Component, exact }, idx) => (
            <Route
              key={idx}
              path={path}
              exact={exact}
              element={<PrivateRoutes child={<Component />} />}
            />
          ))}
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
};

export default App;

//
