import React, { useEffect, useState } from "react";
import { CgMenu } from "react-icons/cg";
import MobileMenu from "../menu/MobileMenu";
import { useLocation } from "react-router-dom";

const MenuBars = ({ className }) => {
  const location = useLocation();

  const [isVisible, setIsVisible] = useState(false);

  const handleVisible = (val) => setIsVisible(val);

  useEffect(() => {
    handleVisible(false);
  }, [location]);

  return (
    <>
      <div
        onClick={() => handleVisible(true)}
        className={`${className} flex items-center justify-center w-[40px] min-w-[40px] rounded-[4px]`}
      >
        <CgMenu color="#000" size={24} />
      </div>
      <MobileMenu visible={isVisible} setVisible={() => handleVisible(false)} />
    </>
  );
};

export default MenuBars;
