import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import "../../assets/scss/_modal.scss";

const Modal = ({ active, setActive, children, onClose }) => {
  const handleClose = () => {
    setActive(false);
    if (onClose) onClose();
  };

  return (
    <div className={`modal-bg ${active && "active"}`} onClick={handleClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-icon" onClick={handleClose}>
          <IoCloseOutline size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
