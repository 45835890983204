import React, { useEffect, useState } from "react";
import { ArrowRight } from "../../assets/utils/ArrowRight";
import { Link } from "react-router-dom";
import { list } from "./list";
import Logo from "../../components/navbar/Logo";
import Social from "./components/Social";
import SocialSidebar from "./components/SocialSidebar";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="footer pt-24">
        <div className="container">
          <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
            {list.map((item, idx) => (
              <div className="flex flex-col gap-[19px]" key={idx}>
                <div
                  className={`text-footerBlack font-600 text-sm ${
                    !item?.title && "opacity-[0]"
                  }`}
                >
                  {item?.title ? item?.title : "hide"}
                </div>
                {item?.childs?.map((child, i) => (
                  <Link
                    to={child?.link}
                    key={i}
                    className="block text-footerGray font-500 text-[12px]"
                  >
                    {child?.name}
                  </Link>
                ))}
              </div>
            ))}
            <div className="flex flex-col gap-[19px]">
              <Logo />
              <div className="text-footerBlack font-600 text-sm">
                В социальных сетях:
              </div>
              <Social />
            </div>
          </div>
          <div className="flex flex-wrap items-center border-t border-[#76797f33] py-4 mt-8">
            <div className="text-[12px] font-500 text-footerGray">
              «2023© ООО «bmix». ИНН 1648054022. Все права защищены»
            </div>
            <a
              href="https://t.me/Abduganiev_R"
              className="ml-auto text-[12px] font-700 text-footerGray"
            >
              Сайт разработал Rahimjon
            </a>
          </div>
        </div>
      </footer>
      <button
        className={`scrollTop ${isVisible && "show"}`}
        onClick={scrollToTop}
      >
        <ArrowRight className="text-[26px] fill-white rotate-[-90deg]" />
      </button>
      <SocialSidebar />
    </>
  );
};

export default Footer;
