import { useQuery } from "react-query";
import { requests } from "../helpers/helpers";
import { APP_STATE } from ".";

export function usePortfolio() {
  return useQuery(APP_STATE.PORTFOLIO, async () => {
    try {
      const response = await requests.fetchPortfolio();
      return response.data;
    } catch (err) {
      throw new Error("Failed to fetch portfolio");
    }
  });
}

export function useContacts() {
  return useQuery(APP_STATE.CONTACTS, async () => {
    try {
      const response = await requests.fetchContacts();
      return response.data;
    } catch (err) {
      throw new Error("Failed to fetch contacts");
    }
  });
}

export function useBanners() {
  return useQuery(APP_STATE.BANNERS, async () => {
    try {
      const response = await requests.fetchBanners();
      return response.data;
    } catch (err) {
      throw new Error("Failed to fetch banners");
    }
  });
}

export function usePartners() {
  return useQuery(APP_STATE.PARTNERS, async () => {
    try {
      const response = await requests.fetchPartners();
      return response.data;
    } catch (err) {
      throw new Error("Failed to fetch partners");
    }
  });
}
