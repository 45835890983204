import React from "react";
import {
  FacebookIcon,
  InstagramIcon,
  TelegramIcon,
} from "../../../assets/utils/SocialIcons";

const Social = ({ className }) => {
  return (
    <div className={`flex items-center flex-wrap gap-4 ${className}`}>
      <a href="https://t.me/bmixuz">
        <TelegramIcon />
      </a>
      <a href="https://www.instagram.com/bmix_uz">
        <InstagramIcon />
      </a>
      <a href="https://www.facebook.com/bmixuz">
        <FacebookIcon />
      </a>
    </div>
  );
};

export default Social;
