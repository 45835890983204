import React from "react";
import { Link } from "react-router-dom";
import { navbarTopList } from "./list";
import { BsTelephone } from "react-icons/bs";
import { useQuery } from "react-query";
import { useContacts } from "../../hooks/useHelpers";
import { removeProbels } from "../../helpers/helpers";
import { APP_STATE } from "../../hooks";

const NavbarTop = () => {
  const { data: contacts } = useQuery(APP_STATE.CONTACTS, useContacts());

  return (
    <div className="bg-[#eceef3] py-[6px]">
      <div className="container">
        <div className="flex items-center md:gap-4 gap-1">
          <div className="md:flex hidden items-center gap-4">
            {navbarTopList.map((item, idx) => (
              <Link
                key={idx}
                to={item.link}
                className="text-sm font-600 text-priceGray hover:text-black"
              >
                {item.name}
              </Link>
            ))}
          </div>
          <a
            href={`tel:${removeProbels(contacts?.first_phone)}`}
            className="md:hidden flex items-center gap-1 text-[12px] text-[#e53935] font-700"
          >
            <BsTelephone size={12} />
            {contacts?.first_phone}
          </a>
          <a
            href="tel:+998950958118"
            className="ml-auto md:text-sm text-[12px] flex bg-[#e53935] font-400 text-white rounded-[4px] px-2 py-1 hover:bg-[#ff3336] duration-200"
          >
            Обратный звонок
          </a>
          <a
            href="."
            className="md:text-sm text-[12px] flex bg-[#e53935] font-400 text-white rounded-[4px] px-2 py-1 hover:bg-[#ff3336] duration-200"
          >
            Адрес
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavbarTop;
