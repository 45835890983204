import { APP_STATE } from ".";

export const setFavoriteProducts = (favorites) => {
  localStorage.setItem(APP_STATE.FAVORITE_PRODUCTS, JSON.stringify(favorites));
};

export const getFavoriteProducts = async () => {
  const favorites = await localStorage.getItem(APP_STATE.FAVORITE_PRODUCTS);
  return favorites ? JSON.parse(favorites) : [];
};

export const toggleFavorite = async (product) => {
  const favorites = await getFavoriteProducts();
  const index = favorites.findIndex((fav) => fav.id === product.id);

  if (index === -1) {
    favorites.push(product);
  } else {
    favorites.splice(index, 1);
  }

  setFavoriteProducts(favorites);
};

export const clearFavorites = () => {
  localStorage.removeItem(APP_STATE.FAVORITE_PRODUCTS);
};
