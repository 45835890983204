import React from "react";
import { Link } from "react-router-dom";

const SubMenuItem = ({ items }) => {
  console.log(items);
  return (
    <div className="mt-[20px]">
      {/* <Link
        to="/filter/category/1"
        className="block text-sm font-600 text-[#141415] mb-[18px]"
      >
        Смартфоны и телефоны
      </Link> */}
      {items?.map(
        (item, idx) =>
          item && (
            <Link
              to={`/filter/category/${item?.slug}`}
              className="block font-500 text-[#27282a] text-[0.8rem] hover:text-[#E63737] mb-[9px]"
              key={idx}
            >
              {item?.name}
              <span className="!font-500 !text-[#9fa4b5] !ml-1">
                {item?.amount_of_prodiucts}
              </span>
            </Link>
          )
      )}
    </div>
  );
};

export default SubMenuItem;
