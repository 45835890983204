import React, { useEffect, useState } from "react";
import CatalogIcon from "./CatalogIcon";
import Menu from "../menu/Menu";
import { useLocation } from "react-router-dom";

const Catalog = () => {
  const location = useLocation().pathname;
  const [open, setOpen] = useState(false);

  const handleOpen = (value) => {
    setOpen(value);
  };

  useEffect(() => {
    handleOpen(false);
  }, [location]);

  return (
    <>
      <button
        onClick={() => handleOpen((val) => !val)}
        className="hidden xl:flex items-center gap-[6px] rounded-main font-500 text-primary px-4 bg-bgPrimary hover:bg-bgPrimaryHover h-full text-sm"
      >
        <CatalogIcon open={open} /> Каталог
      </button>
      <Menu visible={open} onBgClick={() => handleOpen(false)} />
    </>
  );
};

export default Catalog;
