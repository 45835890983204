export const APP_STATE = {
  LOGIN: "login",
  ME: "me",
  PRODUCTS: "products",
  SINGLE_PRODUCT: "singleProduct",
  PRODUCTS_BY_SEARCH: "productsBySearch",
  CATEGORIES: "categories",
  FAVORITE_PRODUCTS: "bmixFavoriteProducts",
  LOGOS: "logos",
  SINGLE_LOGO: "singleLogos",
  CONTACTS: "contacts",
  PORTFOLIO: "portfolio",
  BANNERS: "banners",
  PARTNERS: "partners",
  CREATE_ORDER: "createOrder",
  CREATED_ORDER: "createdOrder",
  GIFTS: "gifts",
  USER_CHANGES: "userChanges",
  STOCKS: "stocks",
  REPORTS: "reports",
  TERMS: "terms",
  USER_ORDERS: "userOrders"
};
