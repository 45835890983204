import React, { useEffect, useState } from "react";
import MenuItem from "./components/MenuItem";
import { Link } from "react-router-dom";
import { ArrowRight } from "../../assets/utils/ArrowRight";
import SubMenuItem from "./components/SubMenuItem";
import { useCategories } from "../../hooks/useCategory";

const Menu = ({ visible, onBgClick }) => {
  const { data: categories, refetch: refetchCategories } = useCategories();

  const [selectedCategory, setSelectedCategory] = useState({});
  console.log(categories?.data?.[0]);

  useEffect(() => {
    if (categories?.data && categories?.data?.length > 0) {
      setSelectedCategory(categories?.data?.[0]);
    }
  }, [categories]);

  useEffect(() => {
    refetchCategories();
  }, [refetchCategories]);

  return (
    <>
      <div className={`menu ${visible && "show"}`}>
        <div className="container">
          <div className="menu-wrapper">
            <div className="categories-block pb-6 border-r border-[#76797f33]">
              {categories?.data?.map((item, idx) => (
                <MenuItem
                  onMouseEnter={() => setSelectedCategory(item)}
                  item={item}
                  key={idx}
                  active={selectedCategory?.id === item?.id}
                />
              ))}
            </div>
            <div className="sub-categories-block pl-6">
              <Link
                to={`/filter/category/${selectedCategory?.id}`}
                className="flex items-center gap-2 text-lg py-[11px] text-[#141415] font-600 hover:text-[#e63737]"
              >
                {selectedCategory?.name}
                <ArrowRight className="text-[14px] stroke-[20]" />
              </Link>
              <div className="flex flex-wrap gap-x-8 pb-6">
                <SubMenuItem items={selectedCategory?.sub_categories} />
              </div>
            </div>
          </div>
        </div>
        <div className="menu-shadow" onClick={onBgClick}></div>
      </div>
      <div
        onClick={onBgClick}
        className={`${
          visible ? "block" : "hidden"
        } fixed top-0 left-0 w-full h-full`}
      ></div>
    </>
  );
};

export default Menu;
