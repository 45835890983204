import React from "react";
import Catalog from "../../components/navbar/Catalog";
import Inner from "../../components/navbar/Inner";
import Logo from "../../components/navbar/Logo";
import Search from "../../components/navbar/Search";
import MenuBars from "../../components/navbar/MenuBars";
import "../../assets/scss/_navbar.scss";

const Navbar = () => {
  return (
    <nav className="navbar sticky top-0 z-[9999] bg-white">
      <div className="container">
        <div className="flex items-center py-4 gap-2 md:h-[72px] h-[66px]">
          <MenuBars className="xl:hidden h-full" />
          <Logo
            className={
              "mr-8 [&>span>img]:md:[min-width:175px] [&>span>img]:md:[width:175px] [&>span>img]:w-[130px]"
            }
          />
          <Catalog />
          <Search className="md:flex hidden" />
          <Inner />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
