export const navbarTopList = [
  {
    name: "Портфолио",
    link: "/portfolio",
  },
  {
    name: "Нанесение логотипа",
    link: "/application",
  },
  {
    name: "Контакты",
    link: "/contacts",
  },
];
