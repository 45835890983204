export const list = [
  {
    title: "О нас",
    childs: [
      {
        name: "О компании",
        link: "/about-company",
      },
      {
        name: "Вакансии",
        link: "/about/job",
      },
      {
        name: "Контакты",
        link: "/contacts",
      },
    ],
  },
  {
    childs: [
      {
        name: "Адрес",
        link: "/contacts",
      },
      {
        name: "Нанесение",
        link: "/application",
      },
    ],
  },
  {
    title: "Партнёрам",
    childs: [
      {
        name: "Стать партнёром",
        link: "/become-supplier",
      },
      {
        name: "Бренд под ключ",
        link: "/brand-key",
      },
    ],
  },
];
