import React from "react";
import { Link } from "react-router-dom";
import Search from "../../components/navbar/Search";
import { useQuery } from "react-query";
import { Shimmer } from "react-shimmer";
import { APP_STATE } from "../../hooks";
import { useLocation } from "react-router-dom";

const SecondNavbar = () => {
  const location = useLocation();
  const splitLocation = location.pathname.split("/");
  const { data: categories, isLoading } = useQuery(APP_STATE.CATEGORIES);
  console.log(splitLocation);

  return (
    <div className="container">
      <div className="xl:flex hidden justify-between pb-4">
        {isLoading
          ? [...Array(5)].map((item, idx) => (
              <Shimmer
                className="rounded-[5px]"
                key={idx}
                width={200}
                height={16}
              />
            ))
          : !isLoading &&
            categories?.data?.map((item, idx) => (
              <Link
                to={`/filter/category/${item?.slug}`}
                key={idx}
                className="text-mainGray hover:text-black text-sm main-title font-500"
              >
                {item?.name}
              </Link>
            ))}
      </div>
      <Search
        inputClass="py-[6px] pl-2 w-full !bg-[#76797f1a] !rounded-r-[8px] !rounded-l-[0]"
        className="md:hidden flex md:flex-row flex-row-reverse !rounded-[8px] !border-none justify-between mb-4 !bg-[#76797f1a]"
        buttonClass={`!bg-[#76797f1a] !pl-2 !w-[40px] [&>svg>path]:!stroke-[#a0a2a7] !rounded-l-[8px]`}
      />
    </div>
  );
};

export default SecondNavbar;
